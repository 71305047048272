import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // El tiempo que los datos se mantienen en caché antes de ser eliminados
      staleTime: 1000 * 60 * 5, // 5 minutos
      // El tiempo que los datos se mantienen en caché sin importar si están inactivos o no
      cacheTime: 1000 * 60 * 10, // 10 minutos
      // No refetch los datos en segundo plano
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});

ReactDOM.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <SidebarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SidebarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
