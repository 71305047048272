import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import GetIPData from './servicios/_helper';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useEffect } from 'react';
function App() {
  const content = useRoutes(router);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      GetIPData.then((val) => val).catch((err) => console.log(err));
    }
  }, []);

  // const queryClient = new QueryClient({
  //   defaultOptions: {
  //     queries: {
  //       // El tiempo que los datos se mantienen en caché antes de ser eliminados
  //       staleTime: 1000 * 60 * 5, // 5 minutos
  //       // El tiempo que los datos se mantienen en caché sin importar si están inactivos o no
  //       cacheTime: 1000 * 60 * 10, // 10 minutos
  //       // No refetch los datos en segundo plano
  //       refetchOnWindowFocus: false,
  //       refetchOnMount: false,
  //       keepPreviousData: true
  //     }
  //   }
  // });
  // GetIPData.then((val) => val).catch((err) => console.log(err));

  // const urls = process.env.REACT_APP_BACKURL;
  // console.log(urls);
  return (
    // <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
    // </QueryClientProvider>
  );
}
export default App;
