import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Tooltip,
  Typography
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import { padding } from '@mui/system';
import { IConfiguracionesAtributtes } from 'src/models/Configuraciones';
import { getConfiguraciones } from 'src/servicios/Configuraciones';
import { useQuery } from 'react-query';
const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  // console.log('RENDERING SIDEBAR');

  const { isError, isLoading, data, error, refetch } = useQuery(
    ['getConfiguraciones'],
    () => {
      // alphaCoreDashInfo(idLocal, inicio, fin);
      return getConfiguraciones();
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );

  if (data) {
    let barcito: IConfiguracionesAtributtes = data;
    return (
      <>
        <SidebarWrapper
          sx={{
            display: {
              xs: 'none',
              lg: 'inline-block'
            },
            position: 'fixed',
            left: 0,
            top: 0,
            background:
              theme.palette.mode === 'dark'
                ? alpha(lighten(theme.header.background, 0.1), 0.5)
                : darken(theme.colors.alpha.black[100], 0.5),
            boxShadow:
              theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={1}
                sx={{
                  width: 52
                }}
                display="-webkit-inline-flex"
              >
                {/* <Logo /> */}
                <Box
                  mx={1}
                  sx={{
                    width: 50
                  }}
                  display="-webkit-inline-flex"
                >
                  <img
                    alt={barcito.NOMBRE_LOCAL_VARIANTE}
                    height={35}
                    src={
                      barcito.IMAGEN_LOGO_ISO_CUADRADO
                        ? process.env.REACT_APP_BACKIMAGES +
                          'otros/' +
                          barcito.IMAGEN_LOGO_ISO_CUADRADO
                        : ''
                    }
                  />
                </Box>
                <Typography
                  variant="h2"
                  paddingLeft={1}
                  display="-webkit-inline-flex"
                  fontWeight="bold"
                  aria-haspopup="true"
                  color="white"
                  lineHeight={1.5}
                  alignContent={'center'}
                >
                  {/* {barcito.NOMBRE_LOCAL} */}
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu />
          </Scrollbar>
          <Divider
            sx={{
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <Box p={2}>
            <Button
              href="https://api.whatsapp.com/send?phone=5491158818952&text=Quisiera%20informaci%C3%B3n%20para%20probar%20el%20servicio"
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="success"
              size="small"
              fullWidth
            >
              Chatea con nosotros
            </Button>
          </Box>
        </SidebarWrapper>
        <Drawer
          sx={{
            boxShadow: `${theme.sidebar.boxShadow}`
          }}
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper
            sx={{
              background:
                theme.palette.mode === 'dark'
                  ? theme.colors.alpha.white[100]
                  : darken(theme.colors.alpha.black[100], 0.5)
            }}
          >
            <Scrollbar>
              <Box mt={3}>
                <Box
                  mx={2}
                  sx={{
                    width: 52
                  }}
                  display="-webkit-inline-flex"
                >
                  <Logo />
                  <Typography
                    variant="h2"
                    paddingLeft={2}
                    display="-webkit-inline-flex"
                    fontWeight="bold"
                    aria-haspopup="true"
                    color="white"
                    lineHeight={1.5}
                    alignContent={'center'}
                  >
                    <img
                      alt={barcito.NOMBRE_LOCAL_VARIANTE}
                      height={45}
                      src={
                        barcito.IMAGEN_LOGO_ISO_CUADRADO
                          ? process.env.REACT_APP_BACKIMAGES +
                            'otros/' +
                            barcito.IMAGEN_LOGO_ISO_CUADRADO
                          : ''
                      }
                    />
                    {/* {barcito.NOMBRE_LOCAL} */}
                  </Typography>
                </Box>
              </Box>
              <Divider
                sx={{
                  mt: theme.spacing(3),
                  mx: theme.spacing(2),
                  background: theme.colors.alpha.trueWhite[10]
                }}
              />
              <SidebarMenu />
            </Scrollbar>
          </SidebarWrapper>
        </Drawer>
      </>
    );
  } else {
    return <>Cargando Menu</>;
  }
}

export default Sidebar;
