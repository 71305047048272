import { IAdministradores_Autorizada } from 'src/models/Admin';

import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { ESTADO_INTERNO_PEDIDO, ESTADO_PEDIDO } from './_enumTypes';
//creating function to load ip address from the API
const GetIPData = async () => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(
      '_clientUA',
      JSON.stringify(navigator.userAgent)
    );
    window.sessionStorage.setItem(
      '_clientAV',
      JSON.stringify(navigator.platform)
    );

    try {
      // const res = await axios.get("https://geolocation-db.com/json/");
      const res = await axios.get('https://api.ipify.org/?format=json');
      // console.log(res.data);
      if (res) {
        window.sessionStorage.setItem('_clientMeta', JSON.stringify(res.data));
      }
    } catch (error) {}
  }
};
export function giveColor(estado: string, color: any) {
  if (estado == ESTADO_PEDIDO[ESTADO_PEDIDO.COMPRANDO]) {
    color = 'primary';
  }
  if (estado == ESTADO_PEDIDO[ESTADO_PEDIDO.NUEVO]) {
    color = 'warning';
  }
  if (estado == ESTADO_PEDIDO[ESTADO_PEDIDO.CONFIRMADO]) {
    color = 'secondary';
  }

  if (estado == ESTADO_PEDIDO[ESTADO_PEDIDO.COMPLETADO]) {
    color = 'success';
  }

  if (estado == ESTADO_PEDIDO[ESTADO_PEDIDO.CANCELADO]) {
    color = 'error';
  }
  return color;
}
export function giveColorINTERNO(estado: string, color: any) {
  if (estado == ESTADO_INTERNO_PEDIDO[ESTADO_INTERNO_PEDIDO.SIN_PROCESAR]) {
    color = 'primary';
  }
  if (estado == ESTADO_INTERNO_PEDIDO[ESTADO_INTERNO_PEDIDO.PREPARADO]) {
    color = 'secondary';
  }
  if (estado == ESTADO_INTERNO_PEDIDO[ESTADO_INTERNO_PEDIDO.ARMADO]) {
    color = 'success';
  }

  if (estado == ESTADO_INTERNO_PEDIDO[ESTADO_INTERNO_PEDIDO.RECHAZAR]) {
    color = 'error';
  }

  if (estado == ESTADO_INTERNO_PEDIDO[ESTADO_INTERNO_PEDIDO.CANCELADO]) {
    color = 'warning';
  }
  return color;
}

export default GetIPData();
export function GenerateRandomPassword() {
  var chars =
    '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var passwordLength = 12;
  var password = '';
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  return password;
}

// export const getSessionName = (): string => {
//   const data = window.sessionStorage.getItem('tokenDelta');

//   if (data) {
//     var decoded = jwt_decode(data) as IAdministradores_Autorizada;
//     return decoded.NOMBRE;
//   }
// };
export const getUserSession = (): IAdministradores_Autorizada => {
  try {
    const data = window.sessionStorage.getItem('tokenDelta');

    if (data) {
      return jwt_decode(data) as IAdministradores_Autorizada;
    } else {
      return null;
    }
  } catch (error) {
    window.location.replace('/login');
  }
};
export function CheteateLaFecha4String(fechafile: string) {
  let fechaResult = fechafile;
  try {
    let splitDate = fechafile.split('T', 2);

    let fecha = ` ${splitDate[0].substring(8, 10)}/${splitDate[0].substring(
      5,
      7
    )}/${splitDate[0].substring(0, 4)}`;

    let tiempo = ` ${splitDate[1].substring(0, 2)}:${splitDate[1].substring(
      3,
      5
    )}:${splitDate[1].substring(6, 8)}`;

    fechaResult = fecha + ' ' + tiempo;
  } catch (error) {
    if (fechaResult.length >= 19) fechaResult = fechaResult.substring(0, 19);
  }

  return fechaResult;
}
export function CheteateLaFecha4Date(fechafile: Date) {
  // let fechaResult = fechafile;

  const datae = new Date(fechafile);
  // let fechaResult = datae.setHours(
  //   datae.getHours() - datae.getTimezoneOffset() / 60
  // );

  // try {
  //   if (fechaResult) {
  //     return CheteateLaFecha4String(fechaResult.toString());
  //   }
  // } catch (error) {}

  return datae.toLocaleString();
}
export const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)

  //  formatterCurrency.format(    );
});
export function detectDevice(navigator_browser: string, SO_DISTRO: string) {
  if (navigator_browser.match(/Android/i)) {
    if (SO_DISTRO.includes('Linux')) {
      return 'Android Web';
    } else {
      return 'Android ' + SO_DISTRO;
    }
  }
  if (navigator_browser.match(/webOS/i)) {
    return 'webOS';
  }
  if (navigator_browser.match(/iPad/i)) {
    if (SO_DISTRO == 'iPad') {
      return 'iPad Web';
    } else {
      return 'iPad ' + SO_DISTRO;
    }
  }
  if (navigator_browser.match(/Macintosh/i)) {
    return 'Macintosh';
  }
  if (navigator_browser.match(/BlackBerry/i)) {
    return 'BlackBerry';
  }
  if (navigator_browser.match(/Linux x86_64/i)) {
    return 'BlackBerry';
  }
  if (navigator_browser.match(/iPhone/i)) {
    // if (SO_DISTRO == 'iPhone') {
    //   return 'iPhone Web';
    // } else {
    return 'iPhone ' + SO_DISTRO;
    // }
  }
  if (navigator_browser.match(/Windows/i)) {
    return 'Windows';
  }
  return 'Otros ' + SO_DISTRO;
}
