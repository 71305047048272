import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import jwt_decode from 'jwt-decode';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import { useLocation } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

import { IAdministradores_Autorizada } from 'src/models/Admin';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));
const Login = Loader(lazy(() => import('src/content/Login')));
// Dashboards

const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

// PRIVATE ROUTE AND CHECK ROL
const PrivateRoute = ({ children }) => {
  const data = window.sessionStorage.getItem('tokenDelta');
  // var dataDELTA = jwt_decode(data) as IAdministradores_Autorizada;

  const location = useLocation();
  let isAuthenticated = false;
  //console.log(data);

  if (data) {
    try {
      isAuthenticated = true;
      var decoded = jwt_decode(data) as IAdministradores_Autorizada;
      let hasRol = false;
      var dateNow = new Date();

      if (decoded.exp * 1000 < dateNow.getTime()) {
        alert('Session Expirada, vuelva a loggearse');
        window.sessionStorage.removeItem('tokenDelta');
        // window.sessionStorage.removeItem('deltaDATA');
        window.location.replace('/');
        // }
      }

      if (location.pathname == '/' || location.pathname == '/home') {
        hasRol = true;
      }
      if (
        (location.pathname.indexOf('/clientes') > -1 ||
          location.pathname.indexOf('/consultas') > -1) &&
        decoded.A1
      ) {
        hasRol = true;
      }
      if (location.pathname.indexOf('/administradores') > -1 && decoded.A2) {
        hasRol = true;
      }
      if (location.pathname.indexOf('/pedidos') > -1 && decoded.A3) {
        hasRol = true;
      }
      if (
        (location.pathname.indexOf('/productos') > -1 ||
          location.pathname.indexOf('/coloresytalles') ||
          location.pathname.indexOf('/precioslote') > -1 ||
          location.pathname.indexOf('/categoriasysubtipos') > -1) &&
        decoded.A4
      ) {
        hasRol = true;
      }
      if (
        (location.pathname.indexOf('/banners') > -1 ||
          location.pathname.indexOf('/carrousels') > -1 ||
          location.pathname.indexOf('/sliders') > -1 ||
          location.pathname.indexOf('/menuheader') > -1) &&
        decoded.A5
      ) {
        hasRol = true;
      }
      if (
        (location.pathname.indexOf('/pagoyenvios') > -1 ||
          location.pathname.indexOf('/descuentos') > -1) &&
        decoded.A6
      ) {
        hasRol = true;
      }

      if (
        (location.pathname.indexOf('/despuesfavoritos') > -1 ||
          location.pathname.indexOf('/hitproductos') > -1 ||
          location.pathname.indexOf('/hitcontenidos') > -1) &&
        decoded.V7
      ) {
        hasRol = true;
      }
      if (decoded.SA) {
        hasRol = true;
      }
      if (!hasRol) {
        alert('A donde queremos ir toro????');
        return;
      }
    } catch (error) {
      console.log(decoded);
      console.log(error);
      isAuthenticated = false;
    }
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/login" replace />;
};

// DELTA CUSTOM PAGES
const HomeAdmin = Loader(lazy(() => import('src/content/home')));

const Administradores = Loader(
  lazy(() => import('src/content/administradores'))
);
const EditAdministrador = Loader(
  lazy(() => import('src/content/administradores/Edit'))
);

const Productos = Loader(lazy(() => import('src/content/productos')));
const PreciosLote = Loader(lazy(() => import('src/content/PreciosPorLote')));
const CustomList = Loader(lazy(() => import('src/content/CustomList')));
const EditCustomList = Loader(
  lazy(() => import('src/content/CustomList/Edit'))
);
const EditProducto = Loader(lazy(() => import('src/content/productos/Edit')));

const ColoresYTalles = Loader(lazy(() => import('src/content/coloresytalles')));
const Categorias = Loader(
  lazy(() => import('src/content/categoriasysubtipos'))
);
const Subcategorias = Loader(
  lazy(() => import('src/content/categoriasysubtipos/indexsubcategoria'))
);
const Subtipos = Loader(
  lazy(() => import('src/content/categoriasysubtipos/indexsubtipo'))
);
const Clientes = Loader(lazy(() => import('src/content/clientes')));
const EditCliente = Loader(lazy(() => import('src/content/clientes/Edit')));

const Consultas = Loader(lazy(() => import('src/content/consultas')));

const Banners = Loader(lazy(() => import('src/content/contenidos_bcs')));
const Carrousels = Loader(lazy(() => import('src/content/contenidos_bcs')));
const Sliders = Loader(lazy(() => import('src/content/contenidos_bcs')));

const EditContenidoBCS = Loader(
  lazy(() => import('src/content/contenidos_bcs/Edit'))
);

const PagosYEnvios = Loader(lazy(() => import('src/content/pagosyenvios')));

const Descuentos = Loader(lazy(() => import('src/content/descuentos')));
const ConfiguracionDelta = Loader(lazy(() => import('src/content/deltaconf')));

const Pedidos = Loader(lazy(() => import('src/content/pedidos')));
const EditPedidos = Loader(lazy(() => import('src/content/pedidos/Edit')));
const PedidosV2 = Loader(lazy(() => import('src/content/pedidosv2')));
const EditPedidoV2 = Loader(lazy(() => import('src/content/pedidosv2/Edit')));
const ComprarDespuesFavoritos = Loader(
  lazy(() => import('src/content/despuesfavoritos'))
);

const HitProductos = Loader(lazy(() => import('src/content/hitproductos')));
const HitContenidos = Loader(lazy(() => import('src/content/hitcontenidos')));
//
// {
//   path: 'dashboards',
//   element:       <PrivateRoute> <SidebarLayout /></PrivateRoute>,
//   children: [
//     {
//       path: '',
//       element: <Navigate to="tasks" replace />
//     },
//     {
//       path: 'tasks',
//       element: <Tasks />
//     },
//     {
//       path: 'messenger',
//       element: <Messenger />
//     }
//   ]
// },

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: (
          <PrivateRoute>
            <SidebarLayout />
          </PrivateRoute>
        ),
        children: [
          // {
          //   path: 'administradores',
          //   element: <Administradores />
          // }
          {
            path: '',
            element: <Navigate to="pedidos" replace />
          },
          {
            path: 'home',
            element: <HomeAdmin />
          },
          {
            path: 'productos',
            children: [
              {
                path: '',
                element: <Productos />
              },
              {
                path: ':id',
                element: <EditProducto />
              }
            ]
          },
          {
            path: 'precioslote',
            element: <PreciosLote />
          },
          {
            path: 'customlist',
            children: [
              {
                path: '',
                element: <CustomList />
              },
              {
                path: ':id',
                element: <EditCustomList />
              }
            ]
          },
          {
            path: 'coloresytalles',
            children: [
              {
                path: '',
                element: <ColoresYTalles />
              }
            ]
          },
          {
            path: 'categoriasysubtipos',
            children: [
              {
                path: '',
                element: <Categorias />
              },
              {
                path: ':id',
                element: <Subcategorias />
              },
              {
                path: 'subcate/:id',
                element: <Subtipos />
              }
            ]
          },
          {
            path: 'administradores',
            children: [
              {
                path: '',
                element: <Administradores />
              },
              {
                path: ':id',
                element: <EditAdministrador />
              }
            ]
          }, //Consultas
          {
            path: 'clientes',
            children: [
              {
                path: '',
                element: <Clientes />
              },
              {
                path: ':id',
                element: <EditCliente />
              }
            ]
          }, ////Consultas
          {
            path: 'pedidos',
            children: [
              {
                path: '',
                element: <PedidosV2 />
              },
              {
                path: ':id',
                element: <EditPedidoV2 />
              }
            ]
          }, //
          {
            path: 'consultas',
            children: [
              {
                path: '',
                element: <Consultas />
              }
            ]
          }, //
          {
            path: 'banners',
            children: [
              {
                path: '',
                element: <Banners />
              },
              {
                path: ':id',
                element: <EditContenidoBCS />
              }
            ]
          }, //
          {
            path: 'carrousels',
            children: [
              {
                path: '',
                element: <Carrousels />
              },
              {
                path: ':id',
                element: <EditContenidoBCS />
              }
            ]
          }, //
          {
            path: 'sliders',
            children: [
              {
                path: '',
                element: <Sliders />
              },
              {
                path: ':id',
                element: <EditContenidoBCS />
              }
            ]
          },
          {
            path: 'despuesfavoritos',
            children: [
              {
                path: '',
                element: <ComprarDespuesFavoritos />
              }
            ]
          },
          {
            path: 'hitproductos',
            children: [
              {
                path: '',
                element: <HitProductos />
              }
            ]
          },

          {
            path: 'hitcontenidos',
            children: [
              {
                path: '',
                element: <HitContenidos />
              }
            ]
          },
          {
            path: 'pagoyenvios',
            children: [
              {
                path: '',
                element: <PagosYEnvios />
              }
            ]
          }, //Descuentos
          {
            path: 'descuentos',
            children: [
              {
                path: '',
                element: <Descuentos />
              }
            ]
          }, //Descuentos
          {
            path: 'deltaconf',
            children: [
              {
                path: '',
                element: <ConfiguracionDelta />
              }
            ]
          } //Descuentos
        ]
      },
      // {
      //   path: 'overview',
      //   element: <Navigate to="/" replace />
      // },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />
      },
      {
        path: 'tasks',
        element: <Tasks />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
