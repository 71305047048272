import { useContext, useState } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Collapse
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DashboardIcon from '@mui/icons-material/Dashboard';
//Pedidos
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ListAltIcon from '@mui/icons-material/ListAlt';
//Productos
import CheckroomIcon from '@mui/icons-material/Checkroom';
import PaletteIcon from '@mui/icons-material/Palette';
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded';

//Clientes
import PeopleIcon from '@mui/icons-material/People';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

//COntenidos
import StayPrimaryLandscapeOutlinedIcon from '@mui/icons-material/StayPrimaryLandscapeOutlined';
import PanoramaHorizontalRoundedIcon from '@mui/icons-material/PanoramaHorizontalRounded';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
//metricas
import BookmarksRoundedIcon from '@mui/icons-material/BookmarksRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';

//configuracion
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ChromeReaderModeTwoToneIcon from '@mui/icons-material/ChromeReaderModeTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import CameraFrontTwoToneIcon from '@mui/icons-material/CameraFrontTwoTone';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { getUserSession } from 'src/servicios/_helper';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { IConfiguracionesAtributtes } from 'src/models/Configuraciones';
import { TYPE_SITEFEATURES } from 'src/servicios/_enumTypes';
const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);
const sessionAdmin = getUserSession();

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const siteConfigString = sessionStorage.getItem('delta_siteConfig');
  const siteConfig: IConfiguracionesAtributtes = JSON.parse(
    siteConfigString || '{}'
  );

  const custom_List = siteConfig.SITE_FEATURES.find(
    (e) =>
      e.SITEFEATURES == TYPE_SITEFEATURES[TYPE_SITEFEATURES.LISTAS_CUSTOMIZADAS]
  );

  const { closeSidebar } = useContext(SidebarContext);

  let homeVisible = true;
  let productosVisible = false;
  let administradorVisible = false;
  let clientesVisible = false;
  let pedidosVisible = false;
  let contenidosVisible = false;
  let metricasVisible = false;
  let configVisible = false;
  let deltaCONFIG = false;

  if (sessionAdmin.SA) {
    homeVisible = true;
    productosVisible = true;
    clientesVisible = true;
    pedidosVisible = true;
    contenidosVisible = true;
    metricasVisible = true;
    configVisible = true;
    deltaCONFIG = true;
    administradorVisible = true;
  } else {
    if (sessionAdmin.A1) {
      clientesVisible = true;
    }
    if (sessionAdmin.A2) {
      administradorVisible = true;
    }
    if (sessionAdmin.A3) {
      pedidosVisible = true;
    }
    if (sessionAdmin.A4) {
      productosVisible = true;
    }
    if (sessionAdmin.A5) {
      contenidosVisible = true;
    }
    if (sessionAdmin.A6) {
      configVisible = true;
      deltaCONFIG = true;
    }
    if (sessionAdmin.V7) {
      metricasVisible = true;
    }
  }
  const [menuToggle, setMenuToggle] = useState<boolean>(false);
  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };
  return (
    <>
      <MenuWrapper>
        <List component="div" hidden={!homeVisible}>
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/home"
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Home
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          hidden={!pedidosVisible}
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Pedidos
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/pedidos?filtro=nuevo"
                  startIcon={<NewReleasesIcon />}
                >
                  Nuevos
                </Button>
              </ListItem>

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/pedidos?filtro=enprogreso"
                  startIcon={<QueryBuilderIcon />}
                >
                  En Progreso
                </Button>
              </ListItem> */}
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/pedidos"
                  startIcon={<ListAltIcon />}
                >
                  Todos
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          hidden={!productosVisible}
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Productos y Modelos
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  onClick={toggleMenu}
                  to="/productos"
                  startIcon={<Inventory2Icon />}
                  endIcon={
                    menuToggle ? (
                      <ExpandLessTwoToneIcon />
                    ) : (
                      <ExpandMoreTwoToneIcon />
                    )
                  }
                >
                  Productos
                </Button>
              </ListItem>
              <Collapse in={menuToggle}>
                <ListItem component="div">
                  <Button
                    style={{ paddingLeft: 40 }}
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/productos"
                    startIcon={<CheckroomIcon />}
                  >
                    Productos
                  </Button>
                </ListItem>
                {/* //FORZANDO SOLO PARA QUE ENTRO */}
                {process.env.REACT_APP_DOMO_OFFLINE == 'DOMO_OFFLINE' ? (
                  <ListItem component="div">
                    <Button
                      style={{ paddingLeft: 40 }}
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/precioslote"
                      startIcon={<PriceChangeIcon />}
                    >
                      Precios por Lote
                    </Button>
                  </ListItem>
                ) : null}

                {custom_List ? (
                  <ListItem component="div">
                    <Button
                      style={{ paddingLeft: 40 }}
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/customlist"
                      startIcon={<FormatListNumberedIcon />}
                    >
                      Lista Customizada
                    </Button>
                  </ListItem>
                ) : null}
              </Collapse>

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/categoriasysubtipos"
                  startIcon={<DataObjectRoundedIcon />}
                >
                  Categorias y Subtipos
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/coloresytalles"
                  startIcon={<PaletteIcon />}
                >
                  Colores y Talles
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          hidden={!clientesVisible}
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Clientes
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/clientes"
                  startIcon={<PeopleIcon />}
                >
                  Listado de Clientes
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/consultas"
                  startIcon={<ForwardToInboxIcon />}
                >
                  Consultas
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          hidden={!contenidosVisible}
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Contenidos
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/banners"
                  startIcon={<StayPrimaryLandscapeOutlinedIcon />}
                >
                  Banners
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/carrousels"
                  startIcon={<PanoramaHorizontalRoundedIcon />}
                >
                  Carrousels
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/sliders"
                  startIcon={<ViewCarouselIcon />}
                >
                  Sliders
                </Button>
              </ListItem>

              {/*  //TODO HIDDEN HO TODO <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/menuheader"
                  startIcon={<DashboardCustomizeRoundedIcon />}
                >
                  MENU HEADER CUSTOM
                </Button>
              </ListItem> */}
            </List>
          </SubMenuWrapper>
        </List>
        <List
          hidden={!metricasVisible}
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Métricas
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/despuesfavoritos"
                  startIcon={<BookmarksRoundedIcon />}
                >
                  Comprar Despues y Favoritos
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/hitproductos"
                  startIcon={<InventoryRoundedIcon />}
                >
                  HIT's PRODUCTOS
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/hitcontenidos"
                  startIcon={<QueryStatsRoundedIcon />}
                >
                  HIT's CONTENIDOS
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          hidden={!configVisible}
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Configuraciones Delta
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem
                component="div"
                hidden={!administradorVisible}
                sx={{ visibility: administradorVisible ? 'visible' : 'hidden' }}
              >
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/descuentos"
                  startIcon={<MonetizationOnIcon />}
                >
                  Descuentos
                </Button>
              </ListItem>
              <ListItem
                component="div"
                hidden={!administradorVisible}
                sx={{ visibility: administradorVisible ? 'visible' : 'hidden' }}
              >
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/pagoyenvios"
                  startIcon={<PaymentRoundedIcon />}
                >
                  Medios de Pago y Métodos de Envíos
                </Button>
              </ListItem>
              <ListItem
                component="div"
                hidden={!administradorVisible}
                sx={{ visibility: administradorVisible ? 'visible' : 'hidden' }}
              >
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/administradores"
                  startIcon={<AdminPanelSettingsRoundedIcon />}
                >
                  Administradores
                </Button>
              </ListItem>
              <ListItem
                component="div"
                hidden={!deltaCONFIG}
                sx={{ visibility: deltaCONFIG ? 'visible' : 'hidden' }}
              >
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/deltaconf"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Delta
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <div hidden={process.env.NODE_ENV !== 'development'}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Management
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/transactions"
                    startIcon={<TableChartTwoToneIcon />}
                  >
                    Transactions List
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Accounts
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/profile/details"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    User Profile
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/profile/settings"
                    startIcon={<DisplaySettingsTwoToneIcon />}
                  >
                    Account Settings
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Components
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/buttons"
                    startIcon={<BallotTwoToneIcon />}
                  >
                    Buttons
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/modals"
                    startIcon={<BeachAccessTwoToneIcon />}
                  >
                    Modals
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/accordions"
                    startIcon={<EmojiEventsTwoToneIcon />}
                  >
                    Accordions
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/tabs"
                    startIcon={<FilterVintageTwoToneIcon />}
                  >
                    Tabs
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/badges"
                    startIcon={<HowToVoteTwoToneIcon />}
                  >
                    Badges
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/tooltips"
                    startIcon={<LocalPharmacyTwoToneIcon />}
                  >
                    Tooltips
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/avatars"
                    startIcon={<RedeemTwoToneIcon />}
                  >
                    Avatars
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/cards"
                    startIcon={<SettingsTwoToneIcon />}
                  >
                    Cards
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/components/forms"
                    startIcon={<TrafficTwoToneIcon />}
                  >
                    Forms
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Extra Pages
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/status/404"
                    startIcon={<CheckBoxTwoToneIcon />}
                  >
                    Error 404
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/status/500"
                    startIcon={<CameraFrontTwoToneIcon />}
                  >
                    Error 500
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/status/coming-soon"
                    startIcon={<ChromeReaderModeTwoToneIcon />}
                  >
                    Coming Soon
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/status/maintenance"
                    startIcon={<WorkspacePremiumTwoToneIcon />}
                  >
                    Maintenance
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        </div>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
